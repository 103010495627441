<template>
  <div class="">
  
    <div class="flow-content d-flex justify-content-center p-3 "  style="margin-top:4rem">
      <div class="" style="width: 368px;">
        <div class="q-card p-0 quizell-item-card" >
          
        
      <div class="flow-card p-0 align-items-center justify-content-center">
          <div 
        class="integrationImgae bg-preload"
        style="height: 100px; width: 100%; border-radius: 14px">
        <div class="into-preload"></div>
      </div>
      </div>
    </div>
        <div class="q-card p-0 quizell-item-card" >
          
        
      <div class="flow-card p-0 align-items-center justify-content-center">
          <div 
        class="integrationImgae bg-preload"
        style="height: 100px; width: 100%; border-radius: 14px">
        <div class="into-preload"></div>
      </div>
      </div>
    </div>
        <div class="q-card p-0 quizell-item-card" >
          
        
      <div class="flow-card p-0 align-items-center justify-content-center">
          <div 
        class="integrationImgae bg-preload"
        style="height: 100px; width: 100%; border-radius: 14px">
        <div class="into-preload"></div>
      </div>
      </div>
    </div>
    </div>
  </div>
  
  </div>
 
</template>

<script>



export default {

}
</script>

<style scoped>
.delay-time-selection{
  background-color: transparent;
  color: #73738D;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.48px;
outline: none;
border:none;
width:40%;
}
.create-link{
  text-decoration: none;
  color: #73738D;
  font-weight: 400;
  font-size: 12px;
  padding: 6px 7px;
}
.input-box{
  border-radius: 4px;
background: #F4F3F5;
display: flex;
padding: 6px 8px 6px 16px;
align-items: center;
}
.email-selection{
  background-color: transparent;
  color:#73738D;
  font-size: 12px;
  font-weight: 400;
  border:none;
  border-radius: 4px;
background: #F4F3F5;
display: flex;
padding: 6px 8px 6px 16px;
align-items: center;

}
.email-selection:focus-visible{
  outline: 2px solid #4D1B7E
}
.input-box select:focus-visible{
  outline:none;
}
.flow-sidebar{
  height:90vh; 
  width: 340px;
  position:fixed;
  top:0px;
  left:0px;
  box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.06);
}
.flow-content{
  background: #f4f3f5;
  position: absolute;
  right: 0px;
  width: calc(100% - 340px);
  min-height: 90vh;
  max-height: max-content;
  
}
.flow-card{
  display: flex;
padding: 16px 20px;

border-radius: 6px;

background: rgba(77, 27, 126, 0.08);
box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
}
.trigger-content-heading{
  color: #18191C;
  letter-spacing: 0.72px;
text-transform: capitalize;
font-weight: 500;
font-size: 18px;
letter-spacing: 0.72px;
}
.trigger-content-description{
  font-size: 14px;
  font-weight: 400;
  color: #73738D;
  letter-spacing: 0.56px;
  line-height: normal;
}
.segment-name{
  color: #18191C;
  font-weight: 600;
}
.ghost {
  opacity: 0.5;
  
  z-index:1111;
}

.quizell-item-car::after{
 height: 30px;
    width: 1px;
    background:#D9D7DB;
    content: '';
    display: block;
    margin: auto;
}
.quizell-item-card:not(:first-child):before{
 height: 20px;
    width: 1px;
    background:#D9D7DB;
    content: '';
    display: block;
    margin: auto;
}
.quizell-item-card:not(:last-child)::after{
 height: 20px;
    width: 1px;
    background: #D9D7DB;
    content: '';
    display: block;
    margin: auto;
}
</style>